/* eslint-disable @typescript-eslint/no-unused-vars */
import { NumberType, parsePhoneNumberFromString } from 'libphonenumber-js/max';
import * as yup from 'yup';
import { DateTime, isFromDateTimeBeforeToDateTime } from '@/lib/dateTime';
import { isHexString } from '@/lib/isHexString';
import { isUuid4 } from '@/lib/isUuid4';
import { isYupValidationError } from '@/lib/isYupValidationError';

interface IsDateOptions {
  allowUndefined?: boolean;
  allowNull?: boolean;
}

interface IsNotPastDateOptions {
  toleranceMins: number;
  allowUndefined?: boolean;
  allowNull?: boolean;
}

interface UniqueValuesOptions {
  message?: string;
  caseSensitive?: boolean;
  allowEmpty?: boolean;
}

declare module 'yup' {
  interface StringSchema {
    phoneNumber(message: string): this;
    mobilePhoneNumber(message: string): this;
    transformPhoneNumber(): this;
    trimSpaces(): this;
    isHex(message: string, allowEmpty?: boolean): this;
    isDate(message?: string, options?: IsDateOptions): this;
    isNotPastDate(message: string, options: IsNotPastDateOptions): this;
    uuid4(message?: string): this;
    isTime(message: string): this;
    nonNullable(message?: yup.Message): this;
  }

  interface DateSchema {
    filterPastDateTime(message: string): DateSchema;
  }

  interface ArraySchema<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends yup.Flags = '',
  > {
    uniqueProperties(
      message: string,
      mapperKey: string,
      caseSensitive?: boolean,
      allowEmpty?: boolean,
    ): this;
    isEachValueMongoDBId(message?: string): this;
    uniqueValues(options?: UniqueValuesOptions): this;
  }
}

const mobileNumberTypes: NumberType[] = ['MOBILE', 'FIXED_LINE_OR_MOBILE'];

function testPhoneNumber(
  value: string | undefined,
  createError: (opts?: yup.CreateErrorOptions) => yup.ValidationError,
  onlyMobileNumbers = false,
) {
  if (!value) {
    return true;
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(value);
  const isValidPhoneNumber = parsedPhoneNumber?.isValid() ?? false;

  if (!isValidPhoneNumber) {
    return createError();
  }

  const parsedPhoneNumberType = parsedPhoneNumber?.getType();

  if (onlyMobileNumbers && !mobileNumberTypes.includes(parsedPhoneNumberType)) {
    return createError();
  }

  return true;
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'phoneNumber',
  function phoneNumber(errorMessage) {
    return this.test(
      'test-phone-number',
      errorMessage,
      (phoneNumberValue, { createError }) =>
        testPhoneNumber(phoneNumberValue, createError),
    );
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'mobilePhoneNumber',
  function mobilePhoneNumber(errorMessage) {
    return this.test(
      'test-mobile-phone-number',
      errorMessage,
      (phoneNumberValue, { createError }) =>
        testPhoneNumber(phoneNumberValue, createError, true),
    );
  },
);

/**
 * checks user has set a date in the past (manually) in FormDatePicker or FormDateTimePicker
 */
yup.addMethod<yup.DateSchema>(
  yup.date,
  'filterPastDateTime',
  function isPast(errorMessage) {
    return this.test(
      'date-is-in-past',
      errorMessage,
      (value, { createError }) =>
        isFromDateTimeBeforeToDateTime(value, new Date())
          ? createError()
          : true,
    );
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'transformPhoneNumber',
  function transformPhoneNumber() {
    return this.transform((schema: yup.StringSchema, input: string) => {
      if (typeof input !== 'string') {
        return null;
      }

      return parsePhoneNumberFromString(input)?.number || input || null;
    });
  },
);

/**
 * Trim over spaces in between, useful for setupCardId cleanup
 */
yup.addMethod<yup.StringSchema>(yup.string, 'trimSpaces', () =>
  yup
    .string()
    .transform((_value: yup.StringSchema, input) => input?.replace(/\s+/g, '')),
);

/**
 * Check if string is hexadecimal, useful for setupCardId validation
 */
yup.addMethod<yup.StringSchema>(
  yup.string,
  'isHex',
  function isHex(errorMessage, allowEmpty = false) {
    return this.test(
      'test-number-is-hex',
      errorMessage,
      (hexNumber, { createError }) => {
        if (allowEmpty && !hexNumber) {
          return true;
        }

        return isHexString(hexNumber) ? true : createError();
      },
    );
  },
);

/**
 * checks if string is a valid date, useful for validating dates in FormDatePicker
 */
yup.addMethod<yup.StringSchema>(
  yup.string,
  'isDate',
  function isDate(
    errorMessage: string,
    { allowUndefined, allowNull }: IsDateOptions = {},
  ) {
    return this.test('date-valid', errorMessage, (value, { createError }) => {
      if (allowUndefined && typeof value === 'undefined') {
        return true;
      }

      if (allowNull && value === null) {
        return true;
      }

      if (!value) {
        return createError();
      }

      const date = new Date(value);

      return isNaN(date.getTime()) ? createError() : true;
    });
  },
);

/**
 * checks
 */
yup.addMethod<yup.StringSchema>(
  yup.string,
  'isNotPastDate',
  function isNotPastDate(
    errorMessage: string,
    {
      toleranceMins,
      allowUndefined = false,
      allowNull = false,
    }: IsNotPastDateOptions,
  ) {
    return this.test((value, { createError }) => {
      if (allowUndefined && typeof value === 'undefined') return true;
      if (allowNull && value === null) return true;

      const dateWithTolerance =
        new Date(value ?? '').getTime() + toleranceMins * 60 * 1000;

      return dateWithTolerance >= Date.now()
        ? true
        : createError({ message: errorMessage });
    });
  },
);

/**
 * checks if string is uuid4
 */
yup.addMethod<yup.StringSchema>(
  yup.string,
  'uuid4',
  function uuid4(errorMessage) {
    return this.test('uuid4', errorMessage, (value, { createError }) => {
      if (!value) {
        return true;
      }

      return isUuid4(value) ? true : createError();
    });
  },
);

/**
 * checks if string is time with format HH:mm
 */
yup.addMethod<yup.StringSchema>(
  yup.string,
  'isTime',
  function isTime(errorMessage) {
    return this.test('isTime', errorMessage, (value, { createError }) => {
      if (!value) return true;
      const parsedTime = DateTime.fromFormat(value, 'HH:mm');
      return parsedTime.isValid ? true : createError();
    });
  },
);

/**
 * checks if property of array of objects is unique
 */

yup.addMethod(
  yup.array,
  'uniqueProperties',
  function validationIterator(
    message: string,
    mapperKey: string,
    caseSensitive = true,
    allowEmpty = false,
  ) {
    return this.test(
      'uniqueProperties',
      message,
      function uniqueCheck(this, values) {
        const { createError, path } = this;

        if (!values?.length) return true;

        // Map the values to an array of keys to check for uniqueness
        const mappedValues = new Set(
          values
            .map((obj) => {
              // Check caseSensitive parameter to decide if we should ignore the case
              return caseSensitive
                ? obj[mapperKey]
                : obj[mapperKey]?.toLowerCase();
            })
            .filter((value) => (allowEmpty ? !!value : true)), // filter out empty values,
        );

        // If the number of unique keys matches the number of input values, the input values are unique, because of the Set
        if (
          values.filter((value) => (allowEmpty ? !!value[mapperKey] : true))
            .length === mappedValues.size
        ) {
          return true;
        }

        // Otherwise, map over each input value and check if its key is unique
        const errors = values
          .map((value, index) => {
            // Again check caseSensitive parameter to decide if we should ignore the case
            const currentValue = caseSensitive
              ? value[mapperKey]
              : value[mapperKey]?.toLowerCase();

            // If the key is unique, remove it from the Set and return null (will not produce a yup error)
            if (mappedValues.has(currentValue)) {
              mappedValues.delete(currentValue);
              return null;
            }

            return createError({
              path: `${path}[${index}].${mapperKey}`,
              message,
            });
          })
          .filter(isYupValidationError);

        // If there are validation errors, throw a ValidationError with all of the errors
        if (errors?.length > 0) {
          throw new yup.ValidationError(errors);
        }

        // If there are no validation errors, the input values are unique
        return true;
      },
    );
  },
);

/**
 * checks if array of strings contains only unique values
 * throw errors for each duplicate, with path including first value of the duplicate index
 */
yup.addMethod(
  yup.array,
  'uniqueValues',
  function validationIterator(options: UniqueValuesOptions = {}) {
    const {
      message = 'validation.duplicatesNotAllowed',
      caseSensitive = true,
      allowEmpty = false,
    } = options;

    return this.test(
      'uniqueValues',
      message,
      function uniqueCheck(this, values) {
        const { createError, path } = this;

        if (!values?.length) return true;

        const mappedValues = values
          .map((value) => (caseSensitive ? value : value?.toLowerCase()))
          .filter((value) => (allowEmpty ? !!value : true));

        const setValues = new Set(mappedValues);

        const errors = mappedValues
          .map((value, index) => {
            if (setValues.has(value)) {
              setValues.delete(value);
              return null;
            }

            return createError({
              path: `${path}[${index}]`,
              message,
            });
          })
          .filter(isYupValidationError);

        if (errors?.length > 0) {
          throw new yup.ValidationError(errors);
        }

        return true;
      },
    );
  },
);

yup.addMethod(
  yup.array,
  'isEachValueMongoDBId',
  function isEveryValueMongoDBId(errorMessage) {
    return this.test('isEachValueMongoDBId', errorMessage, (values) => {
      if (!Array.isArray(values)) {
        return true;
      }

      return values.every((id) => isHexString(id) && id.length === 24);
    });
  },
);
