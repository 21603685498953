import 'reflect-metadata';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import '@/lib/yupValidationMethods';

const CSRWrapper = dynamic(() => import('../ui/CSRWrapper'), { ssr: false });

export default function Home() {
  return (
    <>
      <Head>
        <title>resivo admin portal</title>
        <meta name="description" content="resivo admin portal" />
        <meta property="og:title" content="resivo admin portal" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <noscript>You need to enable JavaScript to run this app.</noscript>
      <CSRWrapper />
    </>
  );
}
