import {
  DateTime,
  DateTimeFormatOptions,
  Info as DateTimeInfo,
  DateTimeUnit,
  DurationLike,
  LocaleOptions,
  StringUnitLength,
} from 'luxon';

/**
 * this enum is mainly used as a helper for `DateTime.toFormat`.
 * `DateTime.toLocaleString` must use formats provided from `DateTime` like `DateTime.DATE_SHORT`.
 */
export enum DateTimeFormat {
  /**
   * localized numeric date
   * example: MM/DD/YYYY
   */
  DATE_SHORT = 'D',

  /**
   * localized date and time
   * example: MM/DD/YYYY h:mm AM/PM
   */
  DATETIME_SHORT = 'f',

  /**
   * abbreviated named offset
   * example: EST
   */
  OFFSET_SHORT = 'ZZZZ',
}

interface GetFutureMonthsForYearOptions {
  currentYear: number;
  currentMonth: number;
}

/**
 * get luxon DateTime either by JS Date object or ISO string
 */
export const getDateTimeByJSDateOrISO = (
  date?: Date | string | null,
): DateTime | undefined => {
  if (!date) {
    return undefined;
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }

  return DateTime.fromJSDate(date);
};

/**
 * Get a defined date. This can be useful to ensure a value is set
 * @param fallbackDateISOString fallback date (ISO string), that is returned if date is not defined
 * @param date the date to be checked against
 * @return luxon DateTime
 */
export function ensureDateIsDefined(
  fallbackDateISOString: string,
  date?: Date | string | null,
) {
  if (!date) {
    return DateTime.fromISO(fallbackDateISOString);
  }
  return getDateTimeByJSDateOrISO(date);
}

/**
 * Used to format date and time strings by respecting system locale
 */
export const getLocaleString = (
  date?: Date | string | null,
  format: DateTimeFormatOptions = DateTime.DATETIME_SHORT,
  localeOptions: LocaleOptions = {},
): string | null => {
  const dateTime = getDateTimeByJSDateOrISO(date);

  if (dateTime?.isValid) {
    return dateTime.toLocaleString(format, localeOptions);
  }

  return null;
};

/**
 * this function should only be used in rare cases where you can use `getLocaleString`
 * The `getLocaleString` respects the system locale while `getFormattedString` does not.
 */
export const getFormattedString = (
  date: Date | string | null | undefined,
  format: string | DateTimeFormat,
): string | null => {
  const dateTime = getDateTimeByJSDateOrISO(date);

  if (dateTime?.isValid) {
    return dateTime.toFormat(format);
  }

  return null;
};

/**
 * test if fromDate is equal or before toDate, check time as well
 */
export const isFromDateTimeBeforeToDateTime = (
  fromDate?: Date | string | null,
  toDate?: Date | string | null,
): boolean => {
  const fromDateTime = getDateTimeByJSDateOrISO(fromDate);
  const toDateTime = getDateTimeByJSDateOrISO(toDate);

  if (!fromDateTime?.isValid || !toDateTime?.isValid) {
    return false;
  }

  return fromDateTime < toDateTime;
};

/**
 * test if fromDate is equal or before toDate, not check time
 */
export const isFromDayBeforeToDay = (
  fromDate?: Date | string | null,
  toDate?: Date | string | null,
): boolean => {
  const fromDateTime = getDateTimeByJSDateOrISO(fromDate);
  const toDateTime = getDateTimeByJSDateOrISO(toDate);

  if (!fromDateTime?.isValid || !toDateTime?.isValid) {
    return false;
  }

  return fromDateTime.startOf('day') < toDateTime.startOf('day');
};

/**
 * test if date is between fromDate and toDate
 */
export const isDateBetween = (
  date?: Date | string | null,
  startDate?: Date | string | null,
  endDate?: Date | string | null,
): boolean => {
  const dateTime = getDateTimeByJSDateOrISO(date);
  const startDateTime = getDateTimeByJSDateOrISO(startDate);
  const endDateTime = getDateTimeByJSDateOrISO(endDate);

  if (!dateTime?.isValid || !startDateTime?.isValid || !endDateTime?.isValid) {
    return false;
  }

  const isBiggerOrEqualThanStartDay =
    dateTime.toMillis() >= startDateTime.toMillis();
  const isSMallerOrEqualThanEndDay =
    dateTime.toMillis() <= endDateTime.toMillis();

  return isBiggerOrEqualThanStartDay && isSMallerOrEqualThanEndDay;
};

/**
 * This indicates whether the first date is the same as the second supplied date.
 */
export const isSameDate = (
  firstDate?: Date | string | null,
  secondDate?: Date | string | null,
): boolean => {
  const firstDateTime = getDateTimeByJSDateOrISO(firstDate);
  const secondDateTime = getDateTimeByJSDateOrISO(secondDate);

  if (!firstDateTime?.isValid || !secondDateTime?.isValid) {
    return false;
  }

  return (
    firstDateTime.startOf('day').toMillis() ===
    secondDateTime.startOf('day').toMillis()
  );
};

/**
 * add n of seconds, minutes, hours, days, ... to an existing date
 */
export const addAmountOfUnit = (
  date: Date | string,
  duration: DurationLike,
): Date | null => {
  const dateTime = getDateTimeByJSDateOrISO(date);

  if (!dateTime?.isValid) {
    return null;
  }

  const newDateTime = dateTime.plus(duration);

  // if dates are not across the DST boundary, everything is okay and new date can be returned
  // otherwise we have to calculate the offset
  if (dateTime.isInDST === newDateTime.isInDST) {
    return newDateTime.toJSDate();
  }

  const offset = newDateTime.offset - dateTime.offset;

  return newDateTime.plus({ minutes: offset }).toJSDate();
};

/**
 * sets the date to a beginning of a unit
 */
export const startOf = (
  date: Date | string | null | undefined,
  unit: DateTimeUnit,
): Date | null => {
  const dateTime = getDateTimeByJSDateOrISO(date);

  if (dateTime?.isValid) {
    return dateTime.startOf(unit).toJSDate();
  }

  return null;
};

export const isValidDate = (
  date?: Date | string | null,
): date is string | Date => {
  const parsedDate = getDateTimeByJSDateOrISO(date);
  return parsedDate?.isValid || false;
};

export const isValidDateTime = (
  date: Date | DateTime | null | undefined,
): date is DateTime => {
  return (date as DateTime)?.isValid ?? false;
};

export const getFromFormatToLocaleString = (
  date: string,
  format = 'HH:mm',
): string | null => {
  const dateTime = DateTime.fromFormat(date, format);
  if (dateTime?.isValid) {
    return dateTime.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase();
  }

  return null;
};

export const getWeekdays = (
  locale: string,
  format: StringUnitLength = 'short',
) => DateTimeInfo.weekdays(format, { locale });

/**
 *
 * Useful for TimePickers to not be able to select a toDateTime before the fromDate and before the current dateTime (now).
 * It does not allow selecting toDateTime in the past.
 *
 * @param fromDate
 * @param options.allowPast if true, it allows selecting toDateTime in the past
 * @returns Current date if fromDate is undefined or fromDate is in the past, otherwise fromDate. undefined is used to indicate that there is no min to date
 */
export const getMinValidToDateTime = (
  fromDate?: Date | string | null,
  options = { allowPast: false },
): DateTime | undefined => {
  const parsedFromDateTime = getDateTimeByJSDateOrISO(fromDate);
  const now = DateTime.now();

  if (!parsedFromDateTime?.isValid) {
    return options.allowPast ? undefined : now;
  }

  if (options.allowPast) {
    return parsedFromDateTime;
  }

  return parsedFromDateTime > now ? parsedFromDateTime : now;
};

/**
 *
 * Useful for TimePickers to not be able to select a fromDate after the toDate.
 * It does allow selecting fromDate in the past.
 *
 * @param toDate
 * @returns undefined if toDate is undefined, otherwise toDate. undefined is used to indicate that there is no max from date
 */
export const getMaxValidFromDateTime = (
  toDate?: Date | string | null,
): DateTime | undefined => {
  const parsedToDateTime = getDateTimeByJSDateOrISO(toDate);
  if (!parsedToDateTime?.isValid) {
    return undefined;
  }
  return parsedToDateTime;
};

/**
 * Get list of future months as indices for a given year
 */

export const getFutureMonthsForYear = (
  year: number,
  options: GetFutureMonthsForYearOptions,
): number[] => {
  const { currentYear, currentMonth } = options;

  // if passed year is a future year, show all 12 months of that future year
  if (year > currentYear) {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  }

  // if passed year is the current year, show months from current month to December
  if (year === currentYear) {
    const months: number[] = [];

    for (let i = currentMonth; i < 12; i++) {
      months.push(i);
    }

    return months;
  }

  return [];
};

export const getNthDayFromTodayInFormat = (
  n: number,
  format = 'yyyy-MM-dd',
) => {
  const today = DateTime.now();
  const nthDay = today.plus({ days: n });
  return getFormattedString(nthDay.toJSDate(), format) as string;
};

export const sortDates = (
  datesArray: Date[],
  sortOrder: 'asc' | 'desc' = 'asc',
) =>
  [...datesArray].sort((a, b) =>
    sortOrder === 'asc' ? a.getTime() - b.getTime() : b.getTime() - a.getTime(),
  );

/**
 * Get current date as YYYY-MM-DD for a given timezone, return undefined if date is not valid
 */
export const getDateForTimezone = (
  date: Date | string | null | undefined,
  timezone: string,
): string | undefined => {
  const dateTime = getDateTimeByJSDateOrISO(date);

  if (!dateTime?.isValid) {
    return undefined;
  }

  return dateTime.setZone(timezone).toISODate() || undefined;
};

/**
 * Get date part of ISO string ignoring time and timezone, return undefined if it cannot be parsed
 */
export const getDatePartOfISOString = (date: string) => {
  const dateArray = date.split('T');
  const datePart = dateArray[0];

  const dateTime = getDateTimeByJSDateOrISO(date);

  if (!dateTime?.isValid) {
    return undefined;
  }

  return datePart;
};

export { DateTime };
